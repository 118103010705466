import React, { useEffect, useState } from 'react';
import { getBookHref } from './misc.jsx';

export default function Intro() {
  // Hide intro after scrolling down or otherwise on iphone when we scroll down passed the entire page
  // the intro becomes visible from underneath.
  const hidden = useAutoHideIntroAfterScroll();

  return (
    <section id="intro" style={{ backgroundImage: 'url(/workspace.jpg)' }} className={`${hidden ? 'hidden' : ''}`}>
      <div className="content-wrapper">
        <div className="content">
          <h2>GROW YOUR BUSINESS</h2>
          <h1>
            Custom Software
            <br />
            Development
          </h1>
          <div className="button-container">
            <a className="book-button inverse" href={getBookHref()}>
              SCHEDULE CONSULTATION
            </a>
          </div>
        </div>
      </div>
    </section>
  );
}

function useAutoHideIntroAfterScroll() {
  const [hidden, setHidden] = useState(false);

  useEffect(() => {
    const scrollHandler = () => {
      if (window.scrollY > window.innerHeight + 100) {
        setHidden(true);
      } else {
        setHidden(false);
      }
    };
    document.addEventListener('scroll', scrollHandler);
    return () => document.removeEventListener('scroll', scrollHandler);
  }, []);

  return hidden;
}
